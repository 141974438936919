.add-tag {
  position: relative;

  button {
    svg {
      margin-right: 0.5rem;
      font-size: 0.7rem;
    }
  }

  .tag-list {
    position: absolute;
    top: 150%;
    right: 0;
    z-index: 2;

    li {
      padding: 0.5rem 1rem;
      white-space: nowrap;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

.tag-item {
  padding: 0.2rem 1rem;
  border-radius: 30px;
  width: 100%;
  text-align: center;

  &.deactivated,
  &.problematic {
    background: $red-tag;
  }

  &.zero-content,
  &.probation {
    background-color: $pink-tag;
  }

  &.ignore {
    background-color: $grey-tag;
  }

  &.basic,
  &.promising {
    background-color: $yellow-tag;
  }

  &.extra,
  &.proven {
    background-color: $green-tag;
  }
}
