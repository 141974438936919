.home {
  margin-top: -2rem;
  max-width: 100%;

  .notices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
    margin-top: 2rem;

    @media screen and ($mobile-view) {
      grid-template-columns: 1fr;
    }
  }

  .users {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 2rem;
    margin-top: 2rem;

    .block-body {
      height: 300px;
    }

    @media screen and ($mobile-view) {
      display: block;

      .block-body {
        height: 500px;
      }
    }
  }

  @media screen and ($mobile-view) {
    margin-top: -1rem;
  }

  .quick-links {
    margin: 2rem 0;

    .links {
      a {
        margin-right: 1rem;
        text-decoration: underline;
      }
    }
  }
}

.notice-card {
  width: 100%;
  border-radius: 7px;
  // height: 120px;
  background-color: white;
  box-shadow: 6px 11px 41px -28px #796eb1;
  word-wrap: break-word;
  min-width: 0;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  // justify-content: space-evenly;

  .icon {
    padding: 0.8rem 1rem;
    color: white;
    font-size: 1.5rem;
    border-radius: 6px;

    &.blue {
      background: linear-gradient(45deg, #3858f9, #8e79fd);
      box-shadow: 0 7px 25px rgba(128, 116, 252, 0.5);
    }

    &.red {
      background: linear-gradient(45deg, #f53c5b, #fb768c);
      box-shadow: 0 7px 25px rgba(250, 106, 130, 0.4);
    }

    &.green {
      background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
      box-shadow: 0 7px 25px rgba(11, 163, 96, 0.4);
    }

    &.orange {
      background: linear-gradient(45deg, #ff5858 0%, #f09819 100%);
      box-shadow: 0 7px 25px rgba(241, 159, 93, 0.4);
    }

    &.cyan {
      background: linear-gradient(45deg, #58d5ff 0%, #194bf0 100%);
      box-shadow: 0 7px 25px rgba(25, 75, 240, 0.4);
    }
  }

  .text {
    margin-left: 1rem;

    h4 {
      font-size: 2rem;
    }
  }
}
