input:not([type='checkbox']),
select,
textarea {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 0.5rem;
  resize: none;
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

textarea {
  min-height: 100px;
}
