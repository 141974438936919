.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  color: white;
  background: $dark-green;

  a,
  .button-link {
    color: #fff;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .bar-top {
    padding: 1rem;

    img {
      width: 150px;
    }
  }

  .nav-section {
    padding: 1rem;
    padding-right: 0;

    li {
      width: 100%;
    }
    .nav-link {
      padding: 0.7rem 0 0.7rem 1.5rem;
      width: 100%;
      text-align: left;
      outline: none;

      &.active {
        // background: #ffffff60;
        background: linear-gradient(to left, #74968aee, #74968a, #ffffff70);
        border-radius: 30px 0 0 30px;
      }
      svg {
        color: white;
        margin-right: 0.5rem;
      }
    }

    .sub-links {
      padding: 0.5rem 0;
      margin-left: 3rem;

      li {
        padding: 0.3rem 0;
      }
    }
  }

  &.show {
    display: fixed;
    z-index: 10;
    top: 55px;
  }
}
