.shadow {
  box-shadow: 0 2px 20px #ddd;

  &-in {
    box-shadow: inset 0 2px 4px #ddd;
  }

  &-small {
    box-shadow: 0 5px 5px #ddd;
  }

  &-tucked {
    box-shadow: 0 10px 5px -5px #ddd;
  }
}

.smoke-white {
  background: $tera-smoke;
}

.grey-bg {
  background: $tera-grey;
  color: white;
}

.blue-bg {
  background: $tera-blue;
  color: white;
}

.white-bg {
  background: $white;
  background-color: $white !important;
}

.border {
  border: 1px solid $border-color;

  &-top {
    border-top: 1px solid $border-color;
  }

  &-bottom {
    border-bottom: 1px solid $border-color;
  }

  &-left {
    border-left: 1px solid $border-color;
  }

  &-right {
    border-right: 1px solid $border-color;
  }

  &-round {
    border-radius: 50%;
  }

  &-curved {
    border-radius: 5px;
  }
}

.nowrap {
  flex-wrap: nowrap;
}

.scrollable {
  // white-space: nowrap;
  overflow-x: scroll;
}

.scroll-height {
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.highlighted {
  background-color: yellow;
}
