.avatar {
  width: 50px;
  height: 50px;
  object-fit: fill;
}

.media-preview {
  width: 50%;
}

.video-preview {
  width: 50%;
  height: 200px;
}
