//Due to iphone input focus zoom
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
select:focus,
textarea {
  font-size: 16px !important;
}

.no-whitespace-wrap {
  white-space: nowrap;
}

.no-border {
  border: 0 !important;

  &:focus,
  &:focus-visible {
    outline: none;
    border: 0;
    border-color: transparent;
    box-shadow: none !important;
  }
}

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.text-overflow {
  width: 100%;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.item-line {
  padding: 1rem 0.5rem;
  border-bottom: 1px dotted #e2e8f5;
  margin-bottom: 0;
}

.p-abs {
  position: absolute;
}

.p-rel {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.pointer {
  cursor: pointer;
}

.width {
  &-50 {
    width: 50%;
  }
}

.full-w-h {
  width: 100%;
  height: 100%;
}

.full-w {
  width: 100%;
}

.full-h {
  height: 100%;
}

.force-full-h {
  height: 100vh;
}

.force-full-w {
  width: 100vw;
}
//Flex utilities

.column-sm {
  @media screen and ($mobile-view) {
    flex-direction: column;
  }
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  //justify-contents
  &.jc-c {
    justify-content: center;
  }

  &.jc-sb {
    justify-content: space-between;
  }

  &.jc-sa {
    justify-content: space-around;
  }

  &.jc-se {
    justify-content: space-evenly;
  }

  //align-items

  &.al-fs {
    align-items: flex-start;
  }

  &.al-c {
    align-items: center;
  }
}

//padding utilities

.pad {
  &-0 {
    padding: 0;
  }

  &-l-r-0 {
    padding-left: 0;
    padding-right: 0;
  }

  &-0-5 {
    padding: 0.5rem;
  }

  &-1 {
    padding: 1rem;
  }

  &-1-5 {
    padding: 1.5rem;
  }

  &-2-0 {
    padding: 2rem;
  }

  //top-bottom paddings
  &-tb-0-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &-tb-1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  //left-right paddings
  &-lr-1 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  //top paddings
  &-t-0-5 {
    padding-top: 0.5rem;
  }

  &-t-1 {
    padding-top: 1rem;
  }

  &-t-1-5 {
    padding-top: 1.5rem;
  }

  &-t-2-0 {
    padding-top: 2rem;
  }
}

//margin utilities

.marg {
  &-out {
    margin-left: -15px;
    margin-right: -15px;
  }

  &-0 {
    margin: 0;
  }

  &-0-5 {
    margin: 0.5rem;
  }

  &-1 {
    margin: 1rem;
  }

  &-1-5 {
    margin: 1.5rem;
  }

  &-2-0 {
    margin: 2rem;
  }

  &-2 {
    margin: 2rem;
  }

  //top paddings
  &-t-0-5 {
    margin-top: 0.5rem;
  }

  &-t-1 {
    margin-top: 1rem;
  }

  &-t-1-5 {
    margin-top: 1.5rem;
  }

  &-t-2-0 {
    margin-top: 2rem;
  }

  //bottom margins
  &-b-0 {
    margin-bottom: 0 !important;
  }

  &-b-0-5 {
    margin-bottom: 0.5rem;
  }

  &-b-1 {
    margin-bottom: 1rem;
  }

  &-b-2 {
    margin-bottom: 2rem;
  }

  //top-bottom utilities
  &-tb-0-5 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-tb-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  //right margins
  &-r-0-5 {
    margin-right: 0.5rem;
  }

  &-r-1 {
    margin-right: 1rem;
  }

  &-r-auto {
    margin-right: auto;
  }

  //left margins
  &-l-1 {
    margin-left: 1rem;
  }

  &-l-auto {
    margin-left: auto;
  }
}

.center {
  justify-content: center;
  align-items: center;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.auto-center {
  display: block;
  margin: 0 auto;
}

.page-block {
  border-radius: 5px;
}

.border {
  border: 1px solid #ddd;

  &-bottom {
    border-bottom: 1px solid #ddd;
  }

  &-left {
    border-left: 1px solid #ddd;
  }

  &-top {
    border-top: 1px solid #ddd;
  }

  &-right {
    border-right: 1px solid #ddd;
  }
}

.top {
  &-0 {
    top: 0;
  }

  &-2 {
    top: 2rem;
  }

  &-3 {
    top: 3.5rem;
  }

  &-4 {
    top: 4rem;
  }
}

.right {
  &-0 {
    right: 0;
  }
}

.bottom {
  &-0 {
    bottom: 0;
  }
}

.left {
  &-0 {
    left: 0;
  }
}

//Displays
.block {
  display: block;
}

//Z-indexed

.z {
  &-10 {
    z-index: 10;
  }
}

.h-stack {
  display: flex;
  & > * ~ * {
    margin-left: 1rem;
  }
}

.v-stack {
  display: flex;
  flex-direction: column;
  & > * ~ * {
    margin-top: 1rem;
  }
}
