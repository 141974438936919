.gallery-thumb {
  width: 150px;
  height: 200px;
  transition: 0.5s ease all;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
  }

  img {
    object-fit: cover;
  }

  .gallery-title {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #00000090;
    width: 100%;
    color: #fff;
  }
}
