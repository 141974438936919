@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body {
  color: #444;
  font: 400 14px 'Lato', 'sans-serif';
  line-height: 1.4;
}

a {
  color: inherit;

  &:hover {
    color: lighten($tera-black, 40%);
  }
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1.1rem;
}

.f0-8 {
  font-size: 0.8rem;
}

.f0-9 {
  font-size: 0.9rem;
}

.f1-1 {
  font-size: 1.1rem;
}

.f1-2 {
  font-size: 1.2rem;
}

.f1-5 {
  font-size: 1.3rem;
}

.f2-0 {
  font-size: 1.3rem;
}

.bold {
  font-weight: 700;
}

//Font Color

.red-text {
  color: red;
}

.green-text {
  color: $green-text;
}

.blue-text {
  color: $tera-blue;
}

.grey-text {
  color: #777;
}
.amber-text {
  color: #ffbf00;
}

//text utils

.oneline {
  white-space: nowrap;
}

.no-overflow {
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
