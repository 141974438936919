.block {
  border-radius: 5px;
  box-shadow: 0 0 10px #ddd;
  background: #fff;
  margin-bottom: 1.5rem;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 1px solid #ddd;
  }

  &-body {
    padding: 1rem 2rem;
  }
}
