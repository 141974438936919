.data-table {
  &:nth-child(even) {
    min-width: 100%;
    background-color: rgba($dark-green, 0.2);
  }

  @media screen and ($mobile-view) {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    flex-direction: column;

    & > div {
      // flex: 0 0 auto;

      margin-bottom: 0.5rem;
      max-width: none;
      text-align: left;
    }
  }
}
