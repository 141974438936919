$tera-smoke: #f5f5f5;
$tera-blue: #21c0fc;
$tera-green: #83bc23;
$tera-black: #222222;
$tera-grey: #999;
$tera-red: red;

$red-tag: #f75a69;
$pink-tag: #e09da4;
$yellow-tag: #f0e65f;
$green-tag: #92d86d;
$grey-tag: #aaa;

$green-text: #37a000;
$border-color: #ddd;

$dark-green: #4b7466;
$dark-hover: darken($dark-green, 10%);

//Responsive variables

$mobile-view: 'max-width: 580px';
$tablet-view: 'screen and (min-width: 580px) and (max-width: 989px)';
$desktop-view: 'screen and (min-width: 990px)';

$mobile-breakpoint: 580;
$desktop-lower-breakpoint: calc($mobile-breakpoint + 1);
