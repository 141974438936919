.info {
  @media screen and ($mobile-view) {
    flex-direction: column;

    & > div {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
