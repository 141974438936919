.btn-dark {
  background: $dark-green;
  border: 1px solid $dark-green;

  &:hover,
  &:focus,
  &:active {
    background-color: $dark-hover;
    border: 1px solid $dark-hover;
  }

  &--outline {
    border: 1px solid $dark-green;
    color: $dark-green;
    background-color: white;
    &:hover,
    &:focus,
    &:active {
      background-color: #fafafa;
      border: 1px solid $dark-hover;
    }
  }
}
