//Responsive styles

.visible-sm {
  @media screen and (min-width: $desktop-lower-breakpoint) {
    display: none;
  }
}

.visible-lg {
  @media screen and ($mobile-view) {
    display: none;
  }

  @media screen and (min-width: $desktop-lower-breakpoint) {
    display: initial;
  }
}
