.header {
  width: 82.5%;
  z-index: 2;
  position: fixed;
  border-bottom: 1px solid #ddd;

  @media screen and ($mobile-view) {
    width: 100vw;
    top: 0;
    left: 0;
  }
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 1rem;

  .user-initial {
    padding: 0.4rem 0.5rem;
    background: $dark-green;
    color: white;
    border: none;
    font-size: 1.2rem;
  }

  &-nav {
    top: 100%;
    right: 1rem;
    background: #fff;
    padding: 0.5rem 1rem;
  }
}
.header-nav {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .nav-item {
    padding: 0.5rem 1rem;
    color: rgb(165, 165, 165);
    flex: 0 0 auto;
  }

  .active-nav {
    color: $dark-green;
    font-weight: 700;
    border-bottom: 4px solid $dark-green;
  }
}
