.review-card {
  background: #62646a;
  position: relative;
  width: 100%;
  padding-top: 50%;
  overflow: hidden;
  margin-bottom: 2rem;

  &-block {
    background-color: #333436;
    width: calc(100% - 12%);
    height: calc(100% - 16%);
    margin: 4% 6%;
    padding: 1rem;
    border-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 28px 10px -20px #00000030;
    color: white;

    button {
      display: block;
      margin-left: auto;
    }
  }

  .circle-anchor {
    position: absolute;
    top: -140px;
    left: -115px;
    z-index: 1;

    circle {
      transition: 0.35s stroke-dashoffset;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }

  .card-rating {
    margin: 1% 0;
    width: 100%;
    display: flex;
    justify-content: center;

    svg {
      width: 5%;
      font-size: 300%;
    }
  }

  .rating-comment,
  .share-title {
    text-align: center;
    padding: 0.5rem 1rem 0;
  }

  .rating-comment {
    font-size: 1.5rem;
    // margin-top: 2rem;
    padding: 3rem 3rem 1rem;
  }

  .rating-author {
    text-align: center;
    font-size: 1rem;
  }

  .logo {
    width: 20%;
    position: absolute;
    right: 4%;
    bottom: 6%;
  }
}
