.button-link {
  border: none;
  background: none;
  outline: none;

  &:hover {
    text-decoration: underline;
    color: lighten($tera-black, 40%);
  }
}
