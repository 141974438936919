.profile-line {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px dotted #e2e8f5;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }

  @media screen and ($mobile-view) {
    grid-template-columns: 1fr;
    border-bottom: 1px solid #e2e8f5;
  }
}
