html,
body,
#app {
  height: fit-content;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  &.remove_scroll {
    overflow-y: hidden;
  }
  // @media screen and (min-width: $max-viewport) {
  //   max-width: $max-viewport !important;
  //   margin: 0 auto !important;
  // }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &:before,
  &:after {
    @extend *;
  }
}

a {
  text-decoration: none;
  outline: 0 !important;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
}

ul {
  list-style-position: inside;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* IE6/7 support */
.clearfix {
  *zoom: 1;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.page {
  display: grid;
  grid-template-columns: 18% 82%;

  &-main {
    min-height: 100vh;
    width: 100%;
    main {
      // padding: 2rem;
      // margin-top: 80px;
      // position: relative;

      .page-block {
        border-radius: 5px;
        box-shadow: 0 5px 5px #ddd;
      }

      @media screen and ($mobile-view) {
        width: 100%;
        padding: 1rem;
      }
    }
  }

  @media screen and ($mobile-view) {
    // grid-template-columns: 1fr;
    display: block;
  }
}

img {
  max-width: 100%;
}

.logo-loader {
  animation: fadeLoading 2.5s ease 0s infinite normal forwards;
}
