@keyframes fadeLoading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
  }
}
